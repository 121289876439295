import React, { Component } from 'react';
import Image from '../elements/Image';
import ProjectLink from '../molecules/ProjectLink';
import styled from 'styled-components';
import classNames from 'classnames';
import Image800 from '../elements/Image800';

const Preview = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
  height: 100%;
  h2 {
    position: relative;
    width: 2rem;
    height: 100%;
    margin: 0;
    line-height: 1;
    font-weight: normal;
    font-size: 8px;
    letter-spacing: 0.3px;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.typeStyles( -5 )};
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.typeStyles( -4 )};
    }
    span {
      position: absolute;
      bottom: 0;
      right: 100%;
      transform: rotate(-90deg) translateX(100%) translateY(100%);
      transform-origin: bottom right;
      white-space: nowrap;
    }
  }
  .image-holder {
    width: calc( 100% - 2rem );
  }
  &:hover {
    > .image-holder { 
      opacity: 0.8 
    }
  }
`;

const HiddenContent = styled.section`
  display: none;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  background-color: rgba(255,255,255,0.95);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  .button-holder {
    width: 100%;
    height: 6%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p { 
      cursor: pointer;
      &:hover { text-decoration: underline; }
    }
  }
  .image-holder {
    width: 100%;
    height: 84%;
  }
  .info-holder {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2rem;
    p { 
      text-align: center;
      margin: 0; 
      ${props => props.theme.typeStyles( -3 )};
    }
  }
  &.active {
    display: flex;
  }
`;

class WorkPreview extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      active: false,
    }
  }

  handleClick = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  render() {
    return (
      <li key={this.props.node.id}>
        <Preview className="preview">
          <h2><span>{this.props.node.frontmatter.title} &mdash; {this.props.node.frontmatter.year}</span></h2>
          <div className="image-holder" onClick={(e) => this.handleClick(e)}>
            <Image800 imgName={this.props.node.frontmatter.image}/>
          </div>
        </Preview>
        <HiddenContent className={classNames({'active': this.state.active})}>
          <div className="button-holder">
            <p onClick={(e) => this.handleClick(e)}>Close</p>
          </div>
          <div className="image-holder">
            <Image
              imgName={this.props.node.frontmatter.image}
              style={{ width: '100%', height: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
              placeholderStyle={{ objectFit: 'contain' }}
            />
          </div>
          <section className="info-holder">
            <p><strong>{this.props.node.frontmatter.title}</strong><br/>{this.props.node.frontmatter.size} &mdash; {this.props.node.frontmatter.year} &mdash; {this.props.node.frontmatter.material}</p>
          </section>
        </HiddenContent>
      </li>
    )
  }
}

export default WorkPreview;
