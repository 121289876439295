import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

class ProjectLink extends Component {
  render() {
    if ( this.props.title ) {
      return (
        <StaticQuery
          query={graphql`
            query ProjectLinkQuery {
            allMarkdownRemark(
              filter: {
                  fileAbsolutePath: {regex : "\/_posts/projects/"}
              }
            ){
              edges {
                node{
                  id
                  frontmatter {
                    title
                    path
                  }
                }
              }
            }
          }`}
          render={data => {
            const post = data.allMarkdownRemark.edges.find(
              edge => edge.node.frontmatter.title === this.props.title
            );
            if ( !post ) {
              return null
            }
            return (
              <p>
                Included within <Link to={post.node.frontmatter.path}>{this.props.title}</Link>
              </p>
            )
          }}
        />
      )
    } else {
      return null
    }
  }
}

export default ProjectLink;