import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout';
import Image from '../components/elements/Image';
import ProjectWorkList from '../components/organisms/ProjectWorkList';
import styled from 'styled-components';

const Holder = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    .Title {
      grid-column: 3 / 9;
      grid-row: 1 / 2;
      > :first-child { margin-top: 0 }
      > :last-child { margin-bottom: 0 }
    }
    .Text {
      grid-column: 3 / 9;
      margin: 4rem 0;
    }
    .Work {
      grid-column: 1 / 13;
    }
  }
`;

const Image1 = styled.section`
  display: ${props => props.visible ? 'block' : 'none'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 1 / 13;
    margin: 4rem 0;
  }
`;

const Image2 = styled.section`
  display: ${props => props.visible ? 'block' : 'none'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 6 / 13;
    margin: 4rem 0;
  }
`;

const Image3 = styled.section`
  display: ${props => props.visible ? 'block' : 'none'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 1 / 10;
    margin: 4rem 0;
  }
`;

const Image4 = styled.section`
  display: ${props => props.visible ? 'block' : 'none'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 4 / 10;
    margin: 4rem 0;
  }
`;

export default function Project( {
                                   data, // this prop will be injected by the GraphQL query below.
                                 } ) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Holder>
        <header className="Title">
          <h1>{frontmatter.title}</h1>
          <p>{frontmatter.exhibition_dates}</p>
        </header>
        <Image1 visible={frontmatter.image1}>
          <Image
            imgName={frontmatter.image1}
            caption={frontmatter.image1Credit}/>
        </Image1>
        <section className="Text">
          <div dangerouslySetInnerHTML={{ __html: html }}/>
          <p>{frontmatter.address}</p>
        </section>
        <Image2 visible={frontmatter.image2}>
          <Image
            imgName={frontmatter.image2}
            caption={frontmatter.image2Credit}/>
        </Image2>
        <Image3 visible={frontmatter.image3}>
          <Image
            imgName={frontmatter.image3}
            caption={frontmatter.image3Credit}/>
        </Image3>
        <Image4 visible={frontmatter.image4}>
          <Image
            imgName={frontmatter.image4}
            caption={frontmatter.image4Credit}/>
        </Image4>
        <section className="Work">
          <ProjectWorkList work={frontmatter.work}/>
        </section>
      </Holder>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image1
        image1Credit
        image2
        image2Credit
        image3
        image3Credit
        image4
        image4Credit
        address
        exhibition_dates
        work
      }
    }
  }
`;