import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import WorkList from './WorkList';

class ProjectWorkList extends Component {

  render() {
    if ( this.props.work ) {
      return (
        <StaticQuery
          query={graphql`
            query ProjectWorkListQuery {
            allMarkdownRemark(
              filter: {
                  fileAbsolutePath: {regex : "\/_posts/work/"}
              }
            ){
              edges {
                node{
                  id
                  frontmatter {
                    title
                    image
                    material
                    size
                    year
                    project
                  }
                }
              }
            }
          }`}
          render={data => {
            const works = data.allMarkdownRemark.edges.filter(
              edge => this.props.work.includes( edge.node.frontmatter.title )
            );
            if ( !works ) {
              return null
            }
            return (
              <WorkList data={works}/>
            )
          }}
        />
      )
    } else {
      return null
    }
  }
}

export default ProjectWorkList;