import React, { Component } from 'react';
import styled from 'styled-components';
import WorkPreview from './WorkPreview';

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  padding-bottom: 8rem;
  li:nth-child(even) .preview { transform: translateY(8rem); }
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(3, 1fr);
    li:nth-child(even) .preview { transform: translateY(0); }
    li:nth-child(3n + 2) .preview { transform: translateY(8rem); }
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: repeat(4, 1fr);
    li:nth-child(3n + 2) .preview { transform: translateY(0); }
    li:nth-child(even) .preview { transform: translateY(8rem); }
  }
  @media ( ${props => props.theme.breakpoints.xl} ) {
    grid-template-columns: repeat(6, 1fr);
    li:nth-child(3n + 2) .preview { transform: translateY(0); }
    li:nth-child(even) .preview { transform: translateY(8rem); }
  }
`;

class WorkList extends Component {
  render() {
    return (
      <List>
        {this.props.data.map( edge => (
          <WorkPreview key={edge.node.id} node={edge.node}/>
        ) )}
      </List>
    )
  }
}

export default WorkList;